import React, { Fragment, useState, useEffect, useContext } from 'react'
import { MapContext } from '../../context/mapContext'
import * as values from '../../constants/values'

import './Nav.scss'

const Nav = () => {
  const mapConsumer = useContext(MapContext)

  const [listTitle, setListTitle] = useState(null)
  const [currentList, setCurrentList] = useState('all')
  const [renderedList, setRenderedList] = useState(null)
  const [target, setTarget] = useState(null)

  useEffect(() => {
    const destinations = mapConsumer.destinations
    const destinationIndex = destinations.find(destination => destinations.indexOf(destination) === currentList)
    const targetList = currentList === 'all' ?
      destinations :
      destinationIndex.cities ?
        destinationIndex.cities :
        []
    let list = []

    targetList.forEach( target => {
      list.push(<li
        key={target.name}
        id={`nav-${target.name.replace(" ", "")}`}
        className="nav--destinations-item"
        onClick={() => {
          mapConsumer.center.setCenterCoords(target.coords)
          mapConsumer.zoom.setZoomLevel(target.defaultZoom)
          setTarget(target)
        }}>
          {target.name}
        </li>
      )
    })

    if (currentList !== 'all') {
      list.push(<li
        key={`backlink`}
        id={`nav-backlink`}
        className="nav--destinations-item nav--back-link"
        onClick={() => {
          mapConsumer.center.setCenterCoords(values.initialCenter)
          mapConsumer.zoom.setZoomLevel(values.initialZoom)
          document.getElementById('map-search').value = ""
          setTarget({id: 'all', name: null})
        }}>
          {`↩ Back to all`}
        </li>
      )
    }

    setRenderedList(<Fragment>
      <ul className="nav--destinations">
        {list}
      </ul>
    </Fragment>)

  }, [currentList])

  useEffect(() => {
    let targetIndex = mapConsumer.destinations.indexOf(target)
    if (!!target && !!target.id) {
      setListTitle(target.name)
      setCurrentList(target.id)
    } else if (targetIndex !== -1) {
      setListTitle(target.name)
      setCurrentList(targetIndex)
    }
  }, [target])

  return <div id="nav" className={mapConsumer.nav.navVisibility}>
    {!!listTitle && <h2 className="nav--title">{listTitle}</h2>}
    {!!renderedList && renderedList}
  </div>

}
export default Nav
