import React from 'react'

import './Loading.scss'

const Loading = () => {
  return <div className="loading--main">
    <h1>Welcome to The Stone Adventure!</h1>
    <div className="spinner">
      <div className="cube1"></div>
      <div className="cube2"></div>
    </div>
  </div>
}

export default Loading
