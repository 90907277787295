import React, { Fragment, useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../context/firebaseContext'
import { MapProvider } from '../../context/mapContext'
import checkLocalStorage from '../../functions/checkLocalStorage'
import updateUsername from '../../functions/updateUsername'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import * as values from '../../constants/values'

import Main from '../Main/Main'
import Header from '../Header/Header'
import Loading from '../Loading/Loading'
import Tour from '../Tour/Tour'

import './App.scss'
import doSignIn from '../../functions/doSignIn'
import doSignOut from '../../functions/doSignOut'

const MySwal = withReactContent(Swal)

const App = () => {
  const firebaseConsumer = useContext(FirebaseContext)

  /* Authenticated users */
  const [throwOut, setThrowOut] = useState(false)

  /* Non-authenticated user details */
  const [clientId, setClientId] = useState()
  const [ipAddress, setIpAddress] = useState()
  const [username, setUsername] = useState()
  const [tourComplete, setTourComplete] = useState()
  useEffect(() => {
    checkLocalStorage()
    .then(response => {
      setClientId(response.clientId)
      setIpAddress(response.ipAddress)
      setUsername(response.username)
      setTourComplete(response.tourComplete)
    })
  }, [])

  const [showTour, setShowTour] = useState(false)
  useEffect(() => {
    if (tourComplete === false) {
      MySwal.fire({
        title: 'It looks like you\'re new here!',
        text: 'Would you like a tour?',
        confirmButtonText: 'Yes!',
        showCancelButton: true,
        cancelButtonText: 'No!',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
      .then(result => {
        if (result.value) {
          setShowTour(true)
        } else {
          localStorage.setItem('tourComplete', true)
        }
      })
    }
  }, [tourComplete])

  const [initialCenterCoords, setInitialCenterCoords] = useState(values.initialCenter)
  const [centerCoords, setCenterCoords] = useState(values.initialCenter)
  const [zoomLevel, setZoomLevel] = useState(values.initialZoom)

  const [destinations, setDestinations] = useState()
  useEffect(() => {
    firebaseConsumer.getAllDestinations()
    .then( snapshot => {
      setDestinations(snapshot.val())
    })
  }, [])

  const [suggestions, setSuggestions] = useState()
  useEffect(() => {
    firebaseConsumer.getAllSuggestions()
    .then( snapshot => {
      setSuggestions(snapshot.val())
    })
  }, [])

  const [navVisibility, setNavVisibility] = useState('hidden')

  const resetUsername = async () => {
    await updateUsername().then(response => {
      setUsername(response)
      localStorage.setItem('username', response)
    })
  }

  const userSignIn = async () => {
    await doSignIn(firebaseConsumer.doSignInWithEmailAndPassword).then(response => {
      if (!!response.user) {
        setThrowOut(true)
      }
    })
  }

  const userSignOut = async () => {
    await doSignOut(firebaseConsumer.doSignOut).then(response => {
      if (!!response) {
        setThrowOut(false)
      }
    })
  }

  if (!!destinations && !!clientId && !!ipAddress) {
    return <Fragment>
      <MapProvider value={{
        userSignIn: userSignIn,
        userSignOut: userSignOut,
        throwOut,
        clientId,
        ipAddress,
        username,
        resetUsername: resetUsername,
        initialCenter: {initialCenterCoords, setInitialCenterCoords},
        center: {centerCoords, setCenterCoords},
        zoom: {zoomLevel, setZoomLevel},
        destinations,
        suggestions,
        nav: {navVisibility, setNavVisibility}
      }}>
        {!!showTour && <Tour />}
        <Header />
        <Main />
      </MapProvider>
    </Fragment>
  } else {
    return <Loading />
  }
}

export default App
