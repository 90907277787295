import app from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyAqRDtrIwmjtpuQGoVQAynPISDSUMZki8w",
    authDomain: "stone-adventure.firebaseapp.com",
    databaseURL: "https://stone-adventure.firebaseio.com",
    projectId: "stone-adventure",
    storageBucket: "stone-adventure.appspot.com",
    messagingSenderId: "493794194567"
};

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.db = app.database()
    this.auth = app.auth()
  }

  /**
   * Auth API
   */

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()


  /**
   * Destinations API
   */

  destination = id => this.db.ref(`destinations/${id}`)

  getDestination = id => this.db.ref(`destinations/${id}`).once('value').then(snapshot => snapshot)

  allDestinations = () => this.db.ref(`destinations`)

  getAllDestinations = () => this.db.ref(`destinations`).once('value').then(snapshot => snapshot)


  /**
   * Suggestions API
   */

  suggestion = id => this.db.ref(`suggestions/${id}`)

  getSuggestion = id => this.db.ref(`suggestions/${id}`).once('value').then(snapshot => snapshot)

  allSuggestions = () => this.db.ref(`suggestions`)

  getAllSuggestions = () => this.db.ref(`suggestions`).once('value').then(snapshot => snapshot)

  writeNewSuggestion = input => {
    let newSuggestionKey = this.db.ref(`suggestions`).push().key
    this.db.ref(`suggestions/${newSuggestionKey}`).update({...input, active: true})
    return newSuggestionKey
  }

  deleteSuggestion = id => {this.db.ref(`suggestions/${id}`).update({active: false})}
}



export default Firebase
