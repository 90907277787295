import ip from './getClientIp'
import updateUsername from './updateUsername'

const checkLocalStorage = async () => {
  let clientId = localStorage.getItem('clientId')
  let ipAddress = localStorage.getItem('ipAddress')
  let username = localStorage.getItem('username')
  let dateStored = localStorage.getItem('dateStored')
  let tourComplete = localStorage.getItem('tourComplete') || false

  let compareDate = new Date(dateStored)
  let date24Ago = new Date()
  date24Ago.setHours(date24Ago.getHours() - 24)

  if ( !clientId ) {
    clientId = Math.random().toString(36).slice(2)
    localStorage.setItem('clientId', clientId )
  }

  if ( !ipAddress || compareDate.getTime() < date24Ago.getTime() ) {
    await ip().then(response => {
      ipAddress = response.ip
      localStorage.setItem('ipAddress', ipAddress)
    })
  }

  if ( !username ) {
    await updateUsername().then(response => {
      username = response
      localStorage.setItem('username', username)
    })
  }

  localStorage.setItem('dateStored', new Date())

  return {
    clientId,
    ipAddress,
    username,
    tourComplete
  }
}

export default checkLocalStorage
