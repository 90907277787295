import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const doSignIn = async (firebaseDoSignIn) => {
  return new Promise(async (resolve, reject) => {
    const {value: formValues} = await MySwal.fire({
      title: 'Login',
      html:
        '<input type="email" id="login-input1" class="swal2-input" placeholder="email address" >' +
        '<input type="password" id="login-input2" class="swal2-input" placeholder="password">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('login-input1').value,
          document.getElementById('login-input2').value
        ]
      }
    })

    if (formValues) {
      firebaseDoSignIn(formValues[0], formValues[1])
      .then(response => {
        if (!!response.user) {
          MySwal.fire(
            'Sign in successful',
            ':)',
            'success'
          )
          resolve(response)
        }
      })
      .catch(error => {
        MySwal.fire(
          'Sign in failed',
          ':(',
          'error'
        )
        resolve(error)
      })

    }
  })
}

export default doSignIn
