import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const doSignOut = async (firebaseDoSignOut) => {
  let signOut = await MySwal.fire({
    title: 'Sign out?',
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: 'Sign out',
  })
  .then(result => {
    if (result.value) {
      firebaseDoSignOut()
      return result.value
    }

    return false
  })

  return signOut
}

export default doSignOut
