import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const updateUserName = async () => {
  let username = await MySwal.fire({
    title: 'Tell us your name!',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off',
    },
    confirmButtonText: 'OK',
    allowOutsideClick: false,
    allowEscapeKey: false,
  })
  .then(result => {
    if(result.value) {
      return result.value
    } else {
      updateUserName()
    }
  })

  return username
}

export default updateUserName
