import React from 'react'
import Joyride from 'react-joyride'


const Tour = () => {
  const steps = [
    {
      target: '.map-main',
      title: 'The map area',
      content: 'Where all the fun stuff happens! Right now it\'s showing our flight path.',
      isFixed: true,
      disableBeacon: true,
    },
    {
      target: '#nav',
      title: 'The navigation bar',
      content: 'This is everywhere we\'re going, so far! You can click each of these countries for more details.',
      isFixed: true,
      // placement: 'right-start',
    },
    {
      target: '#nav',
      title: 'Country details',
      content: 'After clicking a country, you\'ll see either a list of cities we\'re planning on visiting, or (if we have no set plans yet!) an empty map.',
      isFixed: true,
      // placement: 'right-start'
    },
    {
      target: '#nav',
      title: 'City details',
      content: 'Clicking a city (or zooming into the map manually) will take you closer in, letting you see any current suggestions.',
      isFixed: true,
      // placement: 'right-start'
    },
    {
      target: '.map-main',
      title: 'Suggestions',
      content: 'Clicking on an suggestion marker will show some details about it. Clicking on the map anywhere there isn\'t a marker will let you make a suggestion of your own!',
      isFixed: true,
      placement: 'top-end',
      spotlightClicks: true,
    },
    {
      target: '#map-search',
      content: 'If you know exactly what you\'re looking for, you can search for it here!',
      isFixed: true,
      placement: 'bottom'
    },
    {
      target: '.header--button-username',
      content: 'If you want to change the name you\'re using, you can do that here - at the moment only we can see who made each suggestion!',
      isFixed: true,
      placement: 'bottom'
    }
  ]
  return <Joyride
    steps={steps}
    continuous={true}
    disableScrolling={true}
    disableOverlayClose={true}
    showProgress={true}
    callback={e => {
      if ( (e.action === "next" || e.action === "close") && e.lifecycle === "complete" && e.index === 0 ) {
        const navMenu = document.getElementById('navMenu')
        if (navMenu) {navMenu.click()}
      }
      if ( (e.action === "next" || e.action === "close") && e.lifecycle === "complete" && e.index === 1 ) {
        const nav = document.getElementById('nav-Australia')
        if (nav) { nav.click() }
      }
      if ( (e.action === "next" || e.action === "close") && e.lifecycle === "complete" && e.index === 2 ) {
        const nav = document.getElementById('nav-Sydney')
        if (nav) { nav.click() }
      }
      if ( (e.action === "next" || e.action === "close") && e.lifecycle === "complete" && e.index === 3 ) {
        const navMenu = document.getElementById('navMenu')
        if (navMenu) {navMenu.click()}
        const search = document.getElementById('searchMenu')
        if (search) { search.click()}
      }
      if ( (e.action === "next" || e.action === "close") && e.lifecycle === "complete" && e.index === 4 ) {
        const nav = document.getElementById('nav-backlink')
        if (nav) { nav.click() }
      }
      if (e.action === "reset") {
        const search = document.getElementById('searchMenu')
        if (search) { search.click()}
        localStorage.setItem('tourComplete', true)
      }
    }}
  />

}

export default Tour
