import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react'
import { MapContext } from '../../context/mapContext'
import { FirebaseContext } from '../../context/firebaseContext'
import * as values from '../../constants/values'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './Map.scss'

const MySwal = withReactContent(Swal)

const onMarkerClick = (props, marker, e, functions) => {
  MySwal.fire({
    title: marker.name,
    text: `${marker.text} `,
    footer: `Recommended by ${marker.username}`,
    showCloseButton: true,
    showConfirmButton: functions.throwOut,
    confirmButtonText: 'Delete!',
    showCancelButton: false
  }).then(result => {
    if (result.value) {
      functions.delete(marker.id)
      functions.update({
        ...functions.suggestions,
        [marker.id]: { ...functions.suggestions[marker.id], active: false }
      })
    }
  })
}

const submitPopup = async data => {
  const { value: formValues } = await MySwal.fire({
    title: `Tell us what's here, ${data.username}!`,
    showCancelButton: true,
    html:
      `<input id="submit-form-name" class="swal2-input" placeholder="Name" value="${
        data.name ? data.name : ''
      }" />` +
      `<textarea id="submit-form-description" class="swal2-input" placeholder="Description" rows="5" style="height: 200px; resize: none;" />`,
    focusConfirm: false,
    preConfirm: () => {
      return [
        document.getElementById('submit-form-name').value,
        document.getElementById('submit-form-description').value
      ]
    }
  })

  if (!!formValues && formValues[0].length > 0 && formValues[1].length > 0) {
    const submitObject = {
      name: formValues[0],
      text: formValues[1],
      coords: data.coords,
      ipAddress: data.ipAddress,
      clientId: data.clientId,
      username: data.username
    }

    let newSuggestionKey = data.submit(submitObject)
    data.update({
      ...data.suggestions,
      [newSuggestionKey]: { ...submitObject }
    })
  }
}

const MapContainer = props => {
  const firebaseConsumer = useContext(FirebaseContext)
  const mapConsumer = useContext(MapContext)

  let [destinations] = useState(mapConsumer.destinations)
  let [suggestions, setSuggestions] = useState(mapConsumer.suggestions)
  // useEffect(() => {
  //   console.log("suggestions", suggestions);

  // }, [suggestions])

  let [displayMarkers, setDisplayMarkers] = useState()
  let [markers, setMarkers] = useState()
  useEffect(() => {
    if (typeof markers !== 'undefined') {
      let markerArray = []

      markers.forEach((marker, index) => {
        markerArray.push(
          <Marker
            key={index}
            id={marker.id}
            name={marker.name}
            username={marker.username}
            position={marker.coords}
            text={marker.text}
            onClick={(props, marker, e) => {
              onMarkerClick(props, marker, e, {
                throwOut: mapConsumer.throwOut,
                delete: firebaseConsumer.deleteSuggestion,
                suggestions,
                update: setSuggestions
              })
            }}
          />
        )
      })

      setDisplayMarkers(markerArray)
    }
  }, [markers])

  let [displaySearchMarkers, setDisplaySearchMarkers] = useState()
  let [searchMarkers, setSearchMarkers] = useState([])
  useEffect(() => {
    if (typeof searchMarkers !== 'undefined') {
      if (searchMarkers.length === 0) {
        document.getElementById('map-search').value = ''
      }

      let markerArray = []

      searchMarkers.forEach((marker, index) => {
        markerArray.push(
          <Marker
            key={index}
            position={marker.coords}
            onClick={() => {
              submitPopup({
                coords: {
                  lat: marker.coords.lat(),
                  lng: marker.coords.lng()
                },
                name: marker.name,
                ipAddress: mapConsumer.ipAddress,
                clientId: mapConsumer.clientId,
                username: mapConsumer.username,
                submit: firebaseConsumer.writeNewSuggestion,
                suggestions: suggestions,
                update: setSuggestions
              })
            }}
          />
        )
      })

      setDisplaySearchMarkers(markerArray)
    }
  }, [searchMarkers])

  let [flightPath, setFlightPath] = useState()
  useEffect(() => {
    let flightPathArray = []

    flightPathArray.push(values.londonCenter)

    destinations.forEach(country => {
      if (!!country.cities && country.cities.length > 0) {
        country.cities.forEach(city => {
          flightPathArray.push({ lat: city.coords.lat, lng: city.coords.lng })
        })
      } else {
        flightPathArray.push({
          lat: country.coords.lat,
          lng: country.coords.lng
        })
      }
    })

    flightPathArray.push(values.londonCenter)

    setFlightPath(flightPathArray)
  }, [destinations])

  // let [bounds, setBounds] = useState()
  useEffect(() => {
    // if (typeof bounds !== 'undefined') {
    let markerArray = []
    // let latLngBounds = new props.google.maps.LatLngBounds(bounds.sw, bounds.ne)

    // if (mapZoomLevel >= values.streetMarkerLevel) {
    if (!!suggestions) {
      Object.keys(suggestions).map(suggestion => {
        // if (latLngBounds.contains(new props.google.maps.LatLng(suggestions[suggestion].coords.lat, suggestions[suggestion].coords.lng)) && suggestions[suggestion].active !== false) {
        markerArray.push({
          id: suggestion,
          name: suggestions[suggestion].name,
          coords: {
            lat: suggestions[suggestion].coords.lat,
            lng: suggestions[suggestion].coords.lng
          },
          text: suggestions[suggestion].text,
          username: suggestions[suggestion].username
        })
        // }
        return false
      })
    }
    // } else if (mapZoomLevel >= values.cityMarkerLevel) {
    //   if (!!destinations) {
    //     destinations.forEach(country => {
    //       if (!!country.cities && country.cities.length > 0) {
    //         country.cities.forEach(city => {
    //           if (latLngBounds.contains(new props.google.maps.LatLng(city.coords.lat, city.coords.lng))) {
    //             markerArray.push({name: city.name, coords: {lat: city.coords.lat, lng: city.coords.lng}})
    //           }
    //         })
    //       }
    //     })
    //   }
    // }

    setMarkers(markerArray)
    // }
  }, [
    // bounds,
    suggestions
  ])

  let [mapCoords, setMapCoords] = useState(
    mapConsumer.initialCenter.initialCenterCoords
  )
  useEffect(() => {
    if (typeof mapCoords !== 'undefined') {
      mapConsumer.center.setCenterCoords(mapCoords)
    }
  }, [mapCoords])

  let [mapZoomLevel, setMapZoomLevel] = useState(mapConsumer.zoom.zoomLevel)
  useEffect(() => {
    if (typeof mapZoomLevel !== 'undefined') {
      mapConsumer.zoom.setZoomLevel(mapZoomLevel)
    }
  }, [mapZoomLevel])

  let [map, setMap] = useState()
  useEffect(() => {
    if (typeof map !== 'undefined') {
      if (
        mapConsumer.center.centerCoords.lat !== map.mapObject.center.lat() ||
        mapConsumer.center.centerCoords.lng !== map.mapObject.center.lng()
      ) {
        setMapCoords({
          lat: map.mapObject.center.lat(),
          lng: map.mapObject.center.lng()
        })
      }

      if (mapZoomLevel !== map.mapObject.zoom) {
        setMapZoomLevel(map.mapObject.zoom)
      }

      // let mapBounds = map.mapObject.getBounds()
      // let ne = mapBounds.getNorthEast()
      // let sw = mapBounds.getSouthWest()
      // let nw = new props.google.maps.LatLng(ne.lat(), sw.lng())
      // let se = new props.google.maps.LatLng(sw.lat(), ne.lng())
      // setBounds({ nw, ne, se, sw })
    }
  }, [map])

  let [mapReady, setMapReady] = useState()
  useEffect(() => {
    if (typeof mapReady !== 'undefined') {
      let input = document.getElementById('map-search')
      let searchBox = new props.google.maps.places.SearchBox(input)

      mapReady.map.addListener('bounds_changed', () => {
        searchBox.setBounds(mapReady.map.getBounds())
      })

      let searchMarkerArray = []

      searchBox.addListener('places_changed', () => {
        let places = searchBox.getPlaces()

        if (places.length === 0) {
          return
        }

        searchMarkerArray = []

        let newBounds = new props.google.maps.LatLngBounds()

        places.forEach(place => {
          if (!place.geometry) {
            console.log('Returned place contains no geometry')
            return
          }

          searchMarkerArray.push({
            name: place.name,
            coords: place.geometry.location
          })

          if (place.geometry.viewport) {
            // Only geocodes have viewports
            newBounds.union(place.geometry.viewport)
          } else {
            newBounds.extend(place.geometry.location)
          }
        })

        setSearchMarkers(searchMarkerArray)

        mapReady.map.fitBounds(newBounds)
      })
    }
  }, [mapReady])

  let [mapClick, setMapClick] = useState()
  useEffect(() => {
    if (typeof mapClick !== 'undefined') {
      if (mapClick.props.zoom > 9) {
        setSearchMarkers([])

        submitPopup({
          coords: {
            lat: mapClick.e.latLng.lat(),
            lng: mapClick.e.latLng.lng()
          },
          ipAddress: mapConsumer.ipAddress,
          clientId: mapConsumer.clientId,
          username: mapConsumer.username,
          submit: firebaseConsumer.writeNewSuggestion,
          suggestions: suggestions,
          update: setSuggestions
        })
      }
    }
  }, [mapClick])

  return (
    <Fragment>
      <Map
        google={props.google}
        initialCenter={mapConsumer.initialCenter.initialCenterCoords}
        center={mapConsumer.center.centerCoords}
        zoom={mapConsumer.zoom.zoomLevel}
        onReady={(props, map) => {
          setMapReady({ props, map })
        }}
        onClick={(props, map, e) => {
          setMapClick({ props, map, e })
        }}
        onIdle={(mapProps, mapObject) => {
          setMap({ mapProps, mapObject })
        }}
      >
        {!!flightPath &&
          mapConsumer.zoom.zoomLevel <= values.flightPathLevel && (
            <Polyline
              path={flightPath}
              strokeColor='#0000FF'
              strokeOpacity={0.8}
              strokeWeight={2}
            />
          )}
        {!!markers && markers.length > 0 && displayMarkers}
        {!!searchMarkers && searchMarkers.length > 0 && displaySearchMarkers}
      </Map>
    </Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAoeVVjHgGTxU1LqBulhOsGSM8DKkTfu-I'
})(MapContainer)
