import React, { useState, useContext } from 'react'
import { MapContext } from '../../context/mapContext'

import './Header.scss'

const clearSearch = () => {
  document.getElementById('map-search').value = ''
}

const Header = () => {
  const mapConsumer = useContext(MapContext)

  const [searchVisibility, setSearchVisibility] = useState('hidden')

  return (
    <header className='header'>
      <div className='header--title-container'>
        <h1 className='header--title'>The Stone Adventure</h1>
        <div className='header--greeting-container'>
          <h3 className='header--greeting'>Hi, {mapConsumer.username}!</h3>
          <button
            className='header--button-username'
            onClick={() => {
              mapConsumer.resetUsername()
            }}
          >
            (Use a different name)
          </button>
        </div>
      </div>
      <div className='header--search-container'>
        <button
          id='navMenu'
          className='header--button-nav'
          onClick={() => {
            mapConsumer.nav.setNavVisibility(
              mapConsumer.nav.navVisibility === 'nav' ? 'hidden' : 'nav'
            )
          }}
        >
          Nav
        </button>
        <button
          id='searchMenu'
          className='header--button-search'
          onClick={() => {
            setSearchVisibility(searchVisibility === '' ? 'hidden' : '')
          }}
        >
          Search
        </button>
        <input
          id='map-search'
          className={`header--search-input ${searchVisibility}`}
          type='text'
          placeholder='Search for a location'
        />
        <button
          className={`header--search-input-button ${searchVisibility}`}
          onClick={() => {
            clearSearch()
          }}
        >
          Clear
        </button>
        {/* {!mapConsumer.throwOut && (
          <button
            className='header--button-login'
            onClick={() => {
              mapConsumer.userSignIn()
            }}
          >
            Login
          </button>
        )}
        {!!mapConsumer.throwOut && (
          <button
            className='header--button-login'
            onClick={() => {
              mapConsumer.userSignOut()
            }}
          >
            Logout
          </button>
        )} */}
      </div>
    </header>
  )
}

export default Header
