export const initialZoom = 3

export const initialCenter = { lat: 20, lng: 0 }

export const londonCenter = { lat: 51.5098, lng: -0.1278 }

export const flightPathLevel = 4

export const cityMarkerLevel = 4

export const streetMarkerLevel = 8
