import React, { Fragment } from 'react'
import Nav from '../Nav/Nav'
import MapContainer from '../Map/Map'

import './Main.scss'

const Main = () => {
  return <Fragment>
    <div id="main">
      <div className="map-main">
        <MapContainer />
      </div>
      <Nav />
    </div>
  </Fragment>
}

export default Main
