import React from 'react'
import ReactDOM from 'react-dom'
import Firebase from './services/firebase'
import { FirebaseProvider } from './context/firebaseContext'

import App from './components/App/App'

import './assets/css/normalize.scss'
import './assets/css/global.scss'
import './index.scss'

ReactDOM.render(
  <FirebaseProvider value={ new Firebase() }>
    <App />
  </FirebaseProvider>,
  document.getElementById('root')
)
